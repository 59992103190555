<template>
    <section class="row-card-ikea">
      <div class="card-ikea">
        <img class="card-ikea__img" src="https://www.ikea.com/ext/ingkadam/m/608bda86f7ffbf01/original/PE797856-crop001.jpg?f=xxl" alt="">
        <div class="card-ikea__content">
          <h3>Un toque muy primaveral a base de plantas</h3>
          <p>Descubre como decorar tus estancias con plantas artificiales, siempre verde y sin mantenimiento.</p>
          <button><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
            </svg>
        </button>
        </div>
      </div>
      <div class="card-ikea">
        <img class="card-ikea__img" src="https://www.ikea.com/ext/ingkadam/m/608bda86f7ffbf01/original/PE797856-crop001.jpg?f=xxl" alt="">
        <div class="card-ikea__content">
            <h3>Un toque muy primaveral a base de plantas</h3>
            <p>Descubre como decorar tus estancias con plantas artificiales, siempre verde y sin mantenimiento.</p>
            <button><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
            </button>
        </div>
      </div>
      <div class="card-ikea">
        <img class="card-ikea__img" src="https://www.ikea.com/ext/ingkadam/m/608bda86f7ffbf01/original/PE797856-crop001.jpg?f=xxl" alt="">
        <div class="card-ikea__content">
            <h3>Un toque muy primaveral a base de plantas</h3>
            <p>Descubre como decorar tus estancias con plantas artificiales, siempre verde y sin mantenimiento.</p>
            <button><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
            </button>
        </div>
      </div>
      <div class="card-ikea">
        <img class="card-ikea__img" src="https://www.ikea.com/ext/ingkadam/m/608bda86f7ffbf01/original/PE797856-crop001.jpg?f=xxl" alt="">
        <div class="card-ikea__content">
            <h3>Un toque muy primaveral a base de plantas</h3>
            <p>Descubre como decorar tus estancias con plantas artificiales, siempre verde y sin mantenimiento.</p>
            <button><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
            </button>
        </div>
      </div>
      <div class="card-ikea">
        <img class="card-ikea__img" src="https://www.ikea.com/ext/ingkadam/m/608bda86f7ffbf01/original/PE797856-crop001.jpg?f=xxl" alt="">
        <div class="card-ikea__content">
            <h3>Un toque muy primaveral a base de plantas</h3>
            <p>Descubre como decorar tus estancias con plantas artificiales, siempre verde y sin mantenimiento.</p>
            <button><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
            </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'CardIkea'
  };
  </script>
  
<style lang="scss" scoped>
    .row-card-ikea {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px 20px;

        .card-ikea {
            background: #FDF2F4;
            border-radius: 24px;
            cursor: pointer;
            max-height: 780px;
            max-width: 500px;
            flex-grow: 1;         // Permite que el elemento crezca para ocupar el espacio disponible
            flex-basis: 100%;     // Hace que el elemento inicialmente ocupe todo el ancho disponible
            text-align: left;
            min-width: 290px;     // Establece el ancho mínimo del elemento
            overflow: hidden;

            &:hover {
                opacity: 0.8;
            }

            &:hover button svg {
                transform: rotate(45deg);
                opacity: 0.8;
            }

            &__img {
            height: 50%;
            object-fit: cover;
            width: 100%;
            }

            &__content {
            padding: 32px;

                h3 {
                    color: #333;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 34px;
                    margin-bottom: 24px;
                }

                p {
                    color: #333;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 54px;
                }

                button {
                    background-color: #333;
                    border-radius: 50%;
                    border: none;
                    color: #fff;
                    padding: 16px;
                    height: 56px;
                    width: 56px;

                    svg {
                        transition: 0.3s ease-in-out;
                    }
                }
            }
        }

    }

</style>