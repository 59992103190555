<template>
  <div class="app-container">
    <!-- Header -->
    <header class="header">
      <div class="header-left">
        <input type="text" placeholder="Explore" class="search-input" />
      </div>
      <div class="header-right">
        <span>Home</span>
        <span>Messages</span>
        <span>Notifications</span>
        <img src="https://mighty.tools/mockmind-api/content/human/49.jpg" alt="User" class="avatar" />
      </div>
    </header>

    <!-- Main layout: 3 columns -->
    <div class="main-content">
      <!-- Left Column -->
      <div class="left-column">
        <div class="profile-card">
          <img src="https://placekitten.com/300/100" class="cover-photo" alt="Cover" />
          <img src="https://mighty.tools/mockmind-api/content/human/56.jpg" class="profile-avatar" alt="Avatar" />
          <h2>Yeremias NJ</h2>
          <p>@notojoyoo</p>
          <p>Penting gak Penting yang penting Posting ✨</p>
          <div class="follow-stats">
            <span>6,664 Following</span>
            <span>9,991 Followers</span>
          </div>
          <button class="profile-btn">My Profile</button>
        </div>
        <!-- Suggestions Section -->
        <div class="suggestions">
          <h3>Who to follow</h3>
          <div class="suggestion-item">
            <img src="https://placekitten.com/40/40" class="suggestion-avatar" />
            <div>
              <p>Product Hunt</p>
              <p class="suggestion-handle">@ProductHunt</p>
            </div>
            <button class="follow-btn">Follow</button>
          </div>
        </div>
      </div>

      <!-- Center Column (Tweets) -->
      <div class="center-column">
        <div class="new-tweet">
          <img src="https://mighty.tools/mockmind-api/content/human/44.jpg" class="tweet-avatar" alt="Avatar" />
          <!-- Cambia este textarea -->
          <textarea 
            v-model="newTweetContent"
            placeholder="What's happening?" 
            class="tweet-input">
          </textarea>
          <!-- Cambia este botón -->
          <button class="tweet-btn" @click="addTweet">Tweet</button>
        </div>

        <!-- Lista de tweets (Cambiar la forma en que se muestran) -->
        <div class="tweet" v-for="tweet in tweets" :key="tweet.id">
          <img src="https://placekitten.com/48/48" class="tweet-avatar" />
          <div class="tweet-content">
            <p><strong>{{ tweet.username }}</strong> {{ tweet.handle }} · {{ tweet.time }}</p>
            <p>{{ tweet.content }}</p>
            <div class="tweet-actions">
              <span>Like</span>
              <span>Comment</span>
              <span>Share</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Column (Trends) -->
      <div class="right-column">
        <h3>Trends for you</h3>
        <div class="trend">
          <p>#Minions</p>
          <p>97.7K Tweets</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

// Definir la lista de tweets y el contenido del nuevo tweet
const tweets = ref([
  {
    id: 1,
    username: 'Elon Musk',
    handle: '@elonmusk',
    time: '5m',
    content: "Let's set an age limit after which you can't run for political office...",
  },
]);

// Variable para almacenar el contenido del nuevo tweet
const newTweetContent = ref('');

// Función para agregar un nuevo tweet
const addTweet = () => {
  // Solo agregar el tweet si hay contenido en el textarea
  if (newTweetContent.value.trim() !== '') {
    // Agregar un nuevo tweet a la lista de tweets
    tweets.value.unshift({
      id: Date.now(),  // Asignar un ID único
      username: 'Yeremias NJ',
      handle: '@notojoyoo',
      time: 'Just now',
      content: newTweetContent.value,  // Contenido ingresado por el usuario
    });

    // Limpiar el campo de texto después de agregar el tweet
    newTweetContent.value = '';
  }
};
</script>

<style lang="scss" scoped>
/* General Styles */
/* General Styles */
.app-container {
  font-family: Arial, sans-serif;
  background-color: #111;
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #333;
}

.search-input {
  padding: 0.5rem;
  border-radius: 20px;
  border: none;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Main Layout */
.main-content {
  display: flex;
  flex-grow: 1;
  gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

/* Left Column */
.left-column {
  width: 300px;
  background-color: #222;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }

  .profile-card {
    text-align: center;
    .cover-photo {
      width: 100%;
      height: 100px;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }

    .profile-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-top: -50px;
      border: 4px solid #111;
    }

    .follow-stats {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }

    .profile-btn {
      background-color: #1d9bf0;
      color: white;
      padding: 10px;
      border-radius: 20px;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .suggestions {
    margin-top: 20px;
    .suggestion-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .suggestion-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .follow-btn {
        background-color: #fff;
        color: black;
        padding: 5px 10px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}

/* Center Column */
.center-column {
  flex-grow: 1;
  background-color: #222;
  padding: 20px;
  border-radius: 10px;

  .new-tweet {
    display: flex;
    flex-direction: column;
    .tweet-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .tweet-input {
      background-color: #333;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
      resize: none;
    }

    .tweet-btn {
      background-color: #1d9bf0;
      color: white;
      padding: 10px;
      border-radius: 20px;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  .tweet {
    display: flex;
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;

    .tweet-content {
      margin-left: 10px;

      .tweet-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        color: #888;
      }
    }
  }
}

/* Right Column */
.right-column {
  width: 300px;
  background-color: #222;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
