import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import PlayGroundPage from '../views/PlayGroundPage.vue';
import TwitterClone from '../views/TwitterClone.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/playground', component: PlayGroundPage },
  { path: '/twitter-clone', component: TwitterClone }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;